<template>
  <section class="try-out-form">
    <el-form label-width="120px" :model="form">
      <el-form-item label="用户名" class="required">
        <el-input v-model="form.name"></el-input>
      </el-form-item>
      <el-form-item label="手机号" class="required">
        <el-input v-model="form.phone"></el-input>
      </el-form-item>
      <el-form-item label="公司名称" class="required">
        <el-input v-model="form.companyName"></el-input>
      </el-form-item>
      <el-form-item label="公司所属行业">
        <el-checkbox-group v-model="form.industry">
          <el-checkbox label="美食/餐厅线上活动" name="industry"></el-checkbox>
          <el-checkbox label="地推活动" name="industry"></el-checkbox>
          <el-checkbox label="线下主题活动" name="industry"></el-checkbox>
          <el-checkbox label="单纯品牌曝光" name="industry"></el-checkbox>
        </el-checkbox-group>
      </el-form-item>
      <el-form-item>
        <el-button>返回</el-button>
        <el-button type="primary" @click="onSubmit">确定</el-button>
      </el-form-item>
    </el-form>
  </section>
</template>

<script>
export default {
  data () {
    return ({
      form: {
        name: '',
        phone: '',
        companyName: '',
        industry: []
      }
    })
  },
  methods: {
    onSubmit () {
      console.log('提交')
    }
  }
}
</script>

<style lang="scss">
.try-out-form {
  width: 100%;
  min-height: 100%;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
  padding-top: 50px;
  padding-left: 0px;
  .el-form {
    .el-input {
      width: 425px;
    }
  }
}
</style>
